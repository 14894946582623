import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import RouterCards from 'components/Internet_Access/Port_Forwarding/RouterCards';
import NavButtons from 'components/Internet_Access/NavButtons';
import ForumBox from 'components/Internet_Access/Port_Forwarding/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Port Forwarding",
  "path": "/Internet_Access/Port_Forwarding/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Connect to your Camera through the Internet",
  "image": "./IA_SearchThumb_Portforwarding.png",
  "social": "/images/Search/IA_SearchThumb_Portforwarding.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-Port-Forwarding_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Port Forwarding' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Connect to your Camera through the Internet' image='/images/Search/IA_SearchThumb_Portforwarding.png' twitter='/images/Search/IA_SearchThumb_Portforwarding.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Port_Weiterleitung/' locationFR='/fr/Internet_Access/Port_Forwarding/' crumbLabel="Port Forwarding" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "port-forwarding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#port-forwarding",
        "aria-label": "port forwarding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Port Forwarding`}</h1>
    <h2 {...{
      "id": "connect-to-your-camera-through-the-internet",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connect-to-your-camera-through-the-internet",
        "aria-label": "connect to your camera through the internet permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connect to your Camera through the Internet`}</h2>
    <p>{`The steps to forward a port are almost identical for every internet router. In the following we will give step-by-step instructions for the most common home routers in use. If you did not configure your camera yet - please do `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`Step I`}</a>{` first.`}</p>
    <br />
    <p>{`No Audio with your 1080p Camera? `}<Link to="/Software/Mobile_Access_Over_HTTPS/" mdxType="Link"><button className="btn btnRed float-right">{`HTTPS & Audio`}</button></Link></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "712px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/12fa914590cda748f17ab6ed03c39afb/3d4b6/Portforwarding-Fritzbox-7390de_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVQoz4VS226DMBTr/3/apD1N07ZKDYTcuORCIBRoHzwlKoxuK30wJwJhO/Y58KLB50cGcpL4+qRw5oyuneHdlOYPJvg43fzr/T0OmZpBWQtGK1SlX8kiWjvCu/E2p5vI9I/YhtDYGZxbkJOAFPb24YKmbkGpAC8kSK7wSmYEv4jtOOzbGYJpZERCbQgVKyHyApxLlDogDFf0fiHbcRgf0RnNS1g9oPcX9P6KujSwjUXnR7wcA4wZ0K/ZPiEspUNB6wTThJQZZwpVpSG5hSgUmtomsSXLR0iEGVHIsxJKOsTWdd2jUhZGdwjdNTmOMeyVsclwQptWZUo/h+6yXmlpOsEtTY87mHAgcgahFoyW6cpSmLvVSeRug2cOtT5DN0PKzughFROX+xFiOVqHhHg26znAmiFm+Fd1L3RjA5iowGUD4wYwUePt/QiSCzh3xjd8xkT5Bhq4/gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/12fa914590cda748f17ab6ed03c39afb/e4706/Portforwarding-Fritzbox-7390de_01.avif 230w", "/en/static/12fa914590cda748f17ab6ed03c39afb/d1af7/Portforwarding-Fritzbox-7390de_01.avif 460w", "/en/static/12fa914590cda748f17ab6ed03c39afb/4fb90/Portforwarding-Fritzbox-7390de_01.avif 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/12fa914590cda748f17ab6ed03c39afb/a0b58/Portforwarding-Fritzbox-7390de_01.webp 230w", "/en/static/12fa914590cda748f17ab6ed03c39afb/bc10c/Portforwarding-Fritzbox-7390de_01.webp 460w", "/en/static/12fa914590cda748f17ab6ed03c39afb/c4538/Portforwarding-Fritzbox-7390de_01.webp 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/12fa914590cda748f17ab6ed03c39afb/81c8e/Portforwarding-Fritzbox-7390de_01.png 230w", "/en/static/12fa914590cda748f17ab6ed03c39afb/08a84/Portforwarding-Fritzbox-7390de_01.png 460w", "/en/static/12fa914590cda748f17ab6ed03c39afb/3d4b6/Portforwarding-Fritzbox-7390de_01.png 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/12fa914590cda748f17ab6ed03c39afb/3d4b6/Portforwarding-Fritzbox-7390de_01.png",
              "alt": "Portforwarding AVM Fritzbox",
              "title": "Portforwarding AVM Fritzbox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul">{`see also:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Quick_Installation/Internet_Protocol_IPv6/"
            }}>{`What is IPv6 and how does SLAAC work?`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Frequently_Asked_Question/WQHD_IPv6_Portforwarding/"
            }}>{`Instructions for IPv6 port activation in an AVM Fritzbox`}</a></li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "ii-forwarding-a-port-in-your-router",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ii-forwarding-a-port-in-your-router",
        "aria-label": "ii forwarding a port in your router permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II. Forwarding a Port in your Router`}</h3>
    <p>{`Now we want to enable internet access to our camera. This step is done in your routers web interface, and will look slightly different for different router models. Please be aware that you have to forward a unique port for each of your cameras.`}</p>
    <br /><br />
    <Link to="/Internet_Access/Port_Forwarding/Router_Access/" mdxType="Link"><button className="btn float-right">Router Access</button></Link>
    <h4 {...{
      "id": "port-forwarding-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#port-forwarding-1",
        "aria-label": "port forwarding 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Port Forwarding`}</h4>
    <p>{`The steps of forwarding a port to your camera are almost the same in every router. In the following we present examples for the most common routers.`}</p>
    <RouterCards mdxType="RouterCards" />
    <h3 {...{
      "id": "step-iii--the-cameras-ddns-address-and-the-remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-iii--the-cameras-ddns-address-and-the-remote-access",
        "aria-label": "step iii  the cameras ddns address and the remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step III : The Camera´s DDNS-Address and the Remote-Access`}</h3>
    <Link to="/Internet_Access/Mobile_Access/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Step III</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      